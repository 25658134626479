<template>
    <div class="content">
        <div class="line">
            <van-field v-model="price" type="number" placeholder="请输入金额" class="line-input" :clearable="true"/>
        </div>
        <div class="line">
            <van-button type="info" @click="js_pay" class="line-button">支付</van-button>
            <van-button type="info" @click="handleTest" class="line-button">handleTest</van-button>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';

    export default {
        name: 'home',
        data() {
            return {
                price: '',
                code: '',
                openid: '',
                device_type: '',
                user_id: '',
                alipay_code: '',
                trade_no: ''
            }
        },
        components: {
            // https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxceda6e7887ed0672&redirect_uri=https%3A%2F%2Fjhsq.spjgzs.com%2F%23%2F&response_type=code&scope=snsapi_base&state=123#wechat_redirect

            // https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxceda6e7887ed0672&redirect_uri=https%3A%2F%2Fjhsq.spjgzs.com%2F%23%2F&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect

            // https://jhsq.spjgzs.com/?app_id=2021002100672157&source=alipay_wallet&scope=auth_user&auth_code=6ee085c8a46a426082168b39631fOF18

        },
        created() {
            // this.speaker('1','1');
            let _origin = window.location.origin
            if (_origin.indexOf('localhost') >-1){
                return false
            }
            this.wx_zfb()
            if (this.device_type == 'weixin'){
                if (!!localStorage.openid) {
                    this.openid = localStorage.openid
                }else {
                    if (!!window.location.search){
                        this.code = window.location.search.split('?code=')[1].split('&')[0]
                        this.access_token()
                    }else {
                        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxceda6e7887ed0672&redirect_uri=${window.encodeURIComponent
                        (_origin)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
                    }
                }
            }else if(this.device_type == 'alipay'){
                console.log('ap.parseQueryString',ap.parseQueryString())
                if (!!localStorage.user_id) {
                    this.user_id = localStorage.user_id
                }else {
                    if (!!window.location.search){
                        this.alipay_code = window.location.search.split('auth_code=')[1]
                        this.alipay_auth()
                    }else {
                        window.location.href = `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021002100672157&scope=auth_user&redirect_uri=${window.encodeURIComponent(_origin)}`
                    }
                }
            }else {

            }
        },
        mounted() {

        },
        methods: {
            async handleTest() {
                for (let i = 0; i <=10000; i++) {
                    // console.log(i)

                    let res = await this.$util.ajax('/nas/test', 'POST' ,{num: i})

                    // console.log(res)
                }
            },
            js_pay() {
                if (!this.price) {
                    this.$toast('请输入金额')
                    return false
                }
                console.log(this.device_type)
                if (this.device_type == 'weixin'){
                    this.unifiedorder()
                }else if(this.device_type == 'alipay'){
                    this.alipay_order()
                }else {

                }
            },
            alipay() {
                let that = this
                // console.log(ap)
                ap.tradePay({
                    tradeNO: this.trade_no
                }, function(res){
                    if (res.resultCode == 9000){
                        // that.speaker('支付宝收款'+that.price+'元',that.trade_no)
                        ap.showToast({
                            content: '支付成功',
                            type: 'success'
                        }, function(){
                            ap.popWindow()
                        })
                    }else if(res.resultCode == 8000) {
                        ap.showToast({
                            content: '正在处理中',
                            type: 'none'
                        }, function(){

                        })
                    }else {
                        ap.showToast({
                            content: '支付失败',
                            type: 'fail'
                        }, function(){

                        })
                    }
                });
            },
            alipay_auth() {
                let params = {
                    code: this.alipay_code
                }
                this.$util.ajax('/jh/alipay/auth', 'POST', params).then(res => {
                    console.log(res)
                    if (!!res.data.user_id) {
                        localStorage.user_id = res.data.user_id
                        this.user_id = res.data.user_id
                    }
                })
            },
            alipay_order() {
                let params = {
                    user_id: this.user_id,
                    price: this.price
                }
                console.log(params)
                this.$util.ajax('/jh/alipay/order', 'POST', params).then(res => {
                    console.log(res)
                    this.trade_no = res.data.trade_no
                    this.alipay()
                    // console.log(res)
                    // if (!!res.openid) {
                    //     localStorage.user_id = res.user_id
                    //     this.user_id = res.user_id
                    // }
                })
            },
            access_token() {
                let params = {
                    code: this.code
                }
                this.$util.ajax('/jh/access_token', 'POST', params).then(res => {
                    console.log(res)
                    if (!!res.data.openid) {
                        localStorage.openid = res.data.openid
                        this.openid = res.data.openid
                    }
                })
            },
            unifiedorder() {
                let that = this
                let params = {
                    appid: 'wxceda6e7887ed0672',
                    body: '聚合支付',
                    total_fee: Math.round(this.price * 100),
                    openid: this.openid,
                    trade_type: 'JSAPI'
                }
                this.$util.ajax('/jh/unifiedorder', 'POST', params).then(res => {
                    console.log(res)
                    let obj = res.data

                    function onBridgeReady() {
                        WeixinJSBridge.invoke(
                            'getBrandWCPayRequest', {
                                "appId": obj.appid,
                                "timeStamp": obj.timeStamp.toString(),
                                "nonceStr": obj.nonceStr,
                                "package": obj.package,
                                "signType": "MD5",
                                "paySign": obj.paySign
                            }, function (res) {
                                console.log('WeixinJSBridge:', res)
                                if (res.err_msg == "get_brand_wcpay_request:ok") {
                                    // that.speaker('微信收款'+that.price+'元',obj.nonceStr)
                                    that.$toast('支付成功')
                                    WeixinJSBridge.call('closeWindow')
                                    // window.close()
                                    // 使用以上方式判断前端返回,微信团队郑重提示：
                                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                }else {
                                    that.$toast('支付失败')
                                }
                            });
                    }

                    if (typeof WeixinJSBridge == "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
                            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
                        }
                    } else {
                        onBridgeReady()
                    }
                })
            },
            speaker(message,trace_no) {
                let params = {
                    message: message,
                    trace_no: trace_no
                }
                console.log(params)
                this.$util.ajax('/jh/pay/speaker', 'POST', params).then(res => {
                    console.log(res)
                    // if (!!res.data.openid) {
                    //     localStorage.openid = res.data.openid
                    //     this.openid = res.data.openid
                    // }
                })
            },
            wx_zfb() {
                console.log(window.navigator)
                let ua = window.navigator.userAgent
                console.log(ua)
                if (ua.indexOf('MicroMessenger') > -1){
                    localStorage.device_type = 'weixin'
                }else if (ua.indexOf('AlipayClient') > -1){
                    localStorage.device_type = 'alipay'
                }else {
                    localStorage.device_type = 'h5'
                }
                this.device_type = localStorage.device_type
            }
        }
    }
</script>

<style scoped lang="less">
    .content {
        width: 100%;
        box-sizing: border-box;
        padding: 0.6rem 0.3rem;
        .line {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0.6rem;
            .line-input {
                border-bottom: 1px solid #f0f0f0;
                font-size: 0.5rem;
                color: #000;
            }
            .line-button {
                width: 100%;
            }
        }
    }
</style>
